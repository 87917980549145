
import { getToken } from '../utils/common';
import environment from './environment';
const baseURL = environment.apiUrl;
export const AuthUrl = `${baseURL}/api/BusinessOwners`;
export const imageUrl = `${baseURL}/api`;
export const BASE_URL = `${baseURL}/api`;
export const image_url = `${baseURL}/`;
export const localSubDomain = environment.localSubDomain;
export const API_URL = `${baseURL}/api/`;
export const BACKEND_ROOT = `${baseURL}/`;

// const token = getToken();
// export const header = {
//   Accept: 'application/json',
//   'Content-Type': 'application/json',
//   AUTHORIZATION: token
// };
export const UPLOAD_IMAGE = `${imageUrl}/v1/upload`;
export const catchErr = 'Something went wrong, please try again later.';
export const NETWORK_ERROR = 'Please check your internet connection.';
export const LOGIN = `${AuthUrl}/login`;
export const STAFF = `${BASE_URL}/Staffs`;
export const LOCATION = `${BASE_URL}/getAllLocations`;
export const ADDLOCATION = `${BASE_URL}/Locations`;
export const ADDLOCATIONADDRESS = `${BASE_URL}/LocationAddresses`;
export const FORGOT_PASSWORD = `${AuthUrl}/reset`;
export const CHANGECUSTOMERSTATUS = `${BASE_URL}/change-customer-block-status`;
export const CATEGORY = `${BASE_URL}/Categories`;
export const CUSTOMER = `${BASE_URL}/Customers`;
export const BANNERS = `${BASE_URL}/Promotions`;
export const PROMOCODES = `${BASE_URL}/Promocodes`;
export const PRODUCTS = `${BASE_URL}/Products`;
export const ABOUTUS = `${BASE_URL}/app-content-by-field-name`;
export const UPDATEABOUTUS = `${BASE_URL}/update-app-content`;
export const TERMSANDCONDITIONS = `${BASE_URL}/app-content-by-field-name`;
export const UPDATETERMSANDCONDITIONS = `${BASE_URL}/update-app-content`;
export const FAQS = `${BASE_URL}/app-content-by-field-name`;
export const UPDATEFAQS = `${BASE_URL}/update-app-content`;
export const ProductPromocodes = `${BASE_URL}/ProductPromoCode`;
export const TAXCODES = `${BASE_URL}/TaxCodes`;
export const TAXCODELOCATION = `${BASE_URL}/updatetaxation`;
export const PROMOCODELOCATION = `${BASE_URL}/updatepromocode`;
export const PRODUCTLOCATION = `${BASE_URL}/updateproduct`;
export const PRODUCTPRICING = `${BASE_URL}/ProductPricings`;
export const ADDONS = `${BASE_URL}/AddOns`;
export const ADDONCATEGORY = `${BASE_URL}/AddOnCategories`;
export const UPDATEPRODUCTADDONS = `${BASE_URL}/updateproductaddon`;
export const ORDERDETAILS = `${BASE_URL}/orderdetails`;
export const UPDATEORDERSTATUS = `${BASE_URL}/updateorderstatus`;
export const UPDATEORDERS = `${BASE_URL}/Orders`;
export const FILTERORDERSBYDATERANGE = `${BASE_URL}/filterordersbydaterange`;
export const SETTINGS = `${BASE_URL}/platformSettings`;
export const LOCATIONSCOUNT = `${BASE_URL}/Locations/count`;
export const CUSTOMERSCOUNT = `${BASE_URL}/Customers/count`;
export const DRIVERS = `${BASE_URL}/Drivers`;
export const UPDATE_ORDER_DETAILS = `${BASE_URL}/OrderDetails`;
export const UPDATE_ORDER = `${BASE_URL}/updatedrivers`;
export const LOCATION_MANAGER = `${BASE_URL}/LocationManagers`;
export const TRANSACTIONS = `${BASE_URL}/Transactions`;
export const DELETE_PRODUCT = `${BASE_URL}/delete_product`;
export const DELETE_ADD_ON = `${BASE_URL}/delete-add-on`;